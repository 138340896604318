import { Injectable } from '@angular/core';
import {AuthenticationService} from '../../../../services/system/authentication.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}



@Injectable()
export class NavigationItem {
  constructor(private authenticationService: AuthenticationService) {}

  userRoles: any = [];
  public get() {

    const user = this.authenticationService.getUser();

    if (null !== user && null !== user.roles) {
      this.userRoles = user.roles;
      localStorage.setItem('Roles', this.userRoles);
    }
    if (undefined === this.userRoles) {
      this.userRoles = [];
    }
    // console.log(JSON.stringify(this.userRoles));

    const NavigationItems = [
      {
        title: 'Configuration',
        type: 'collapse',
        icon: 'fas fa-cogs',
        hidden: !(this.userRoles.indexOf('Admin') >= 0),
        children: [
          {
            id: 'datasets-child',
            title: 'Datasets',
            breadcrumb: 'Datasets',
            type: 'collapse',
            children: [
              {
                id: 'view-logs',
                title: 'View Logs',
                breadcrumb: 'Logs',
                type: 'item',
                url: '/Datasets/Logs',
                hidden: false
              }, {
                id: 'datasets-uploadnew',
                title: 'Upload New Dataset',
                breadcrumb: 'Upload New Dataset',
                type: 'item',
                url: '/Datasets/Upload-New-Dataset',
              }, {
                id: 'datasets-uploadnew-uni',
                title: 'Upload New Universal Dataset',
                breadcrumb: 'Upload New Universal Dataset',
                type: 'item',
                url: '/Datasets/Upload-New-Uni-Dataset',
              }
            ]
          },
          {
            title: 'Lookup Templates',
            type: 'collapse',
            children: [
              {
                id: 'new-header',
                title: 'Create New Header',
                type: 'item',
                url: '/Lookup-Header/New-Header'
              },
              {
                id: 'list-headers',
                title: 'View Headers',
                type: 'item',
                url: '/Lookup-Header/View-Headers'
              }
            ]
          },
          {
            id: 'instrument-session',
            title: 'Instrument Session',
            type: 'item',
            url: '/Provider-Framework/Instrument-Session'
          },
          {
            id: 'tag-management',
            title: 'Tag Management',
            type: 'item',
            url: '/Datasets/Tag-Management'
          }
        ]
      },
      {
        title: 'Frameworks',
        type: 'collapse',
        icon: 'fas fa-sitemap',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0),
        children: [
          {
            title: 'NetResult Frameworks',
            type: 'collapse',
            children: [
              {
                id: 'framework-searcy',
                title: 'Search Framework',
                type: 'item',
                url: '/Framework-Builder/Search-Framework'
              },
              // {
              //   id: 'framework-builder',
              //   title: 'Framework Builder',
              //   type: 'item',
              //   url: '/Framework-Builder'
              // },
              {
                id: 'add-framework',
                title: '+ New Framework',
                type: 'item',
                // url: '/Framework-Builder/add'
                url: '/Framework-Builder/New-Framework'
              }
            ]
          },
          {
            title: 'SA Frameworks',
            type: 'collapse',
            children: [
              {
                id: 'sa-section-builder',
                title: 'SA Section Builder',
                type: 'item',
                url: '/Provider-Framework/SA-Section-Builder'
              },
              {
                id: 'add-sa-framework',
                title: '+ New SA Framework',
                breadcrumb: 'newsaframework',
                type: 'item',
                url: '/Provider-Framework/SA-Section-Builder/add'
              }
            ]
          }
        ]
      },
      {
        title: 'Block Data',
        type: 'collapse',
        icon: 'fas fa-cube',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Producer') >= 0),
        children: [
          {
            id: 'search-candidate-data',
            title: 'Search Block Data',
            type: 'item',
            url: '/Section-Design/Search-Design',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          },
          {
            id: 'new-candidate-data',
            title: 'New Block Data',
            type: 'item',
            url: '/Section-Design/New-Design',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0)
          }
        ]
      },
      {
        title: 'Block Design',
        type: 'collapse',
        icon: 'fas fa-palette',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0),
        children: [
          {
            id: 'search-block-design',
            title: 'Search Block Design',
            type: 'item',
            url: '/Block-Design/search-block-design',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          },
          {
            id: 'upload-template',
            title: 'Upload Template',
            type: 'item',
            url: '/Block-Design/upload-template',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0)
          },
          {
            id: 'link-lookup',
            title: 'Create New Block Design',
            type: 'item',
            url: '/Block-Design/link-lookup',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0)
          }
        ]
      },
      {
        title: 'Assets',
        type: 'collapse',
        icon: 'far fa-images',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0),
        children: [
          {
            id: 'search-assets',
            title: 'Search Assets',
            type: 'item',
            url: '/Assets/Search-Assets',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          },
          {
            id: 'new-assets',
            title: 'New Assets',
            type: 'item',
            url: '/Assets/New-Assets',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0)
          }
        ]
      },
      {
        title: 'Sections',
        type: 'collapse',
        icon: 'fas fa-puzzle-piece',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0),
        children: [
          {
            id: 'search-section',
            title: 'Search Section',
            type: 'item',
            url: '/Section-Design/Search-Section',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          },
          {
            id: 'new-section',
            title: 'Create New Section',
            type: 'item',
            url: '/Section-Design/New-Section-Design',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Designer') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          }
          // {
          //   id: 'grapejs-demo',
          //   title: 'Grapesjs Demo',
          //   type: 'item',
          //   url: '/Section-Design/Grapejs-Demo',
          // }
        ]
      },
      {
        title: 'Report',
        type: 'collapse',
        icon: 'fas fa-clipboard-list',
        hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Producer') >= 0),
        children: [
          {
            id: 'search-report',
            title: 'Search Report',
            type: 'item',
            url: '/Report/Search-Report',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Psychologist') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          },
          {
            id: 'new-report',
            title: 'Create New Report',
            type: 'item',
            url: '/Report/Update-Report',
            hidden: !(this.userRoles.indexOf('Admin') >= 0 || this.userRoles.indexOf('Producer') >= 0)
          }
        ]
      },
      {
        title: 'Resource',
        type: 'collapse',
        icon: 'fas fa-project-diagram',
        hidden: !(this.userRoles.indexOf('Admin') >= 0),
        children: [
          {
            id: 'resource-tree',
            title: 'Resource Tree',
            type: 'item',
            url: '/Resource/network',
            hidden: !(this.userRoles.indexOf('Admin') >= 0)
          }, {
            id: 'quick-search',
            title: 'Quick Search',
            type: 'item',
            url: '/Resource/quick-search',
            hidden: !(this.userRoles.indexOf('Admin') >= 0)
          }
        ]
      },
      {
        title: 'Sample Candidate',
        type: 'collapse',
        icon: 'fas fa-project-diagram',
        hidden: !(this.userRoles.indexOf('Admin') >= 0),
        children: [
          {
            id: 'sample-candidate-search',
            title: 'Sample Data',
            type: 'item',
            url: '/Sample-Candidate/Search-Update-Sample-Data',
            hidden: !(this.userRoles.indexOf('Admin') >= 0)
          }
          // {
          //   id: 'sample-candidate',
          //   title: 'New Sample Data',
          //   type: 'item',
          //   url: '/Sample-Candidate/New-Sample-Data',
          //   hidden: !(this.userRoles.indexOf('Admin') >= 0)
          // }
        ]
      }
    ];




    // if (userRoles.length > 0) {
    //   if (userRoles.indexOf('Psychologist')) {
    //
    //   }
    //   if (userRoles.indexOf('Designer')) {
    //
    //   }
    //   if (userRoles.indexOf('Producer')) {
    //
    //   }
    //   if (userRoles.indexOf('Auditor')) {
    //
    //   }
    //   if (userRoles.indexOf('Admin')) {
    //
    //   }
    // } else {
    //   return NavigationItems;
    // }
    return NavigationItems;
  }
}
