import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationIntercepter implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router
    ) {
    }

    private totalRequests = 0;

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let access_token = this.authenticationService.getSessionKey();

        request = request.clone({
            setHeaders: {
                Authorization: access_token ? `Bearer ${access_token}` : ''
            }
        });
        return next.handle(request).pipe(
            tap((event) => {
                if (event instanceof HttpResponse) {
                    if (event && event.body && event.body.newToken) {
                     this.authenticationService.updateToken(event.body.newToken);
                    }
                }
            },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status == 401) {
                            this.authenticationService.redirectToLogin(this.router.url);
                        }
                    }
                }));
    }

}