import {Component, OnInit} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/system/authentication.service';

@Component({
  selector: 'app-nav-right',
  templateUrl: './nav-right.component.html',
  styleUrls: ['./nav-right.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavRightComponent implements OnInit {

  constructor(
    private authenticationService: AuthenticationService
  ) { }

  user: User;  

  ngOnInit() { 
    this.user = this.authenticationService.getUser();
  }

  logout(){
    this.authenticationService.logout();
  }
}
