import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NetworkComponent} from './pages/d3/network/network.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'Datasets/Logs',
        pathMatch: 'full'
      },
      {
        path: 'Datasets',
        loadChildren: () => import('./pages/datasets/datasets.module').then(module => module.DatasetsModule)
      },
      {
        path: 'Framework-Builder',
        loadChildren: () => import('./pages/NR-Framework-Builder/framework-builder.module').then(module => module.CompositeBuilderModule)
      },
      {
        path: 'Framework-Builder/:action',
        loadChildren: () => import('./pages/NR-Framework-Builder/framework-builder.module').then(module => module.CompositeBuilderModule)
      },
      {
        path: 'Block-Design',
        loadChildren: () => import('./pages/block-design/grapesjs-builder.module').then(module => module.GrapesjsBuilderModule)
      },
      {
        path: 'SA-Composite',
        loadChildren: () => import('./pages/sa-composite-builder/sa-composite-builder.module').then(module => module.SaCompositeBuilderModule)
      },
      {
        path: 'Provider-Framework',
        loadChildren: () => import('./pages/SA-Framework-Builder/provider-framework.module').then(module => module.ProviderFrameworkModule)
      },
      {
        path: 'Section-Design',
        loadChildren: () => import('./pages/candidate-section-data/candidate-section-data.module').then(module => module.CandidateSectionDataModule)
      },
      {
        path: 'Lookup-Header',
        loadChildren: () => import('./pages/lookup-table-header/lookup-table-header.module').then(module => module.LookupTableHeaderModule)
      },
      {
        path: 'Handson-Table',
        loadChildren: () => import('./pages/handson-table/handson-table.module').then(module => module.HandsonTableModule)
      },
      {
        path: 'Assets',
        loadChildren: () => import('./pages/assets/assets.module').then(module => module.AssetsModule)
      },
      {
        path: 'Section-Design',
        loadChildren: () => import('./pages/section-design/section-design.module').then(module => module.SectionDesignModule)
      },
      {
        path: 'Report',
        loadChildren: () => import('./pages/report/report.module').then(module => module.ReportModule)
      },
      {
        path: 'Resource',
        loadChildren: () => import('./pages/resource/resource.module').then(module => module.ResourceModule)
      },
      {
        path: 'D3',
        loadChildren: () => import('./pages/d3/d3.module').then(module => module.D3Module)
      },
      {
        path: 'Sample-Candidate',
        loadChildren: () => import('./pages/sample-candidate/sample-candidate.module').then(module => module.SampleCandidateModule)
      },
    ]
  },
  {
    path: 'Resource/tree-view',
    loadChildren: () => import('./pages/resource/quick-search-resource/resource-tree/resource-tree.module').then(module => module.ResourceTreeModule)
  },
  // {
  //   path: 'D3',
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./pages/d3/d3.module').then(module => module.D3Module)
  //     }
  //   ]
  // },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{onSameUrlNavigation:'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
