// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API: {
    BASE_URL: 'https://dataprep2-dot-netresult-platform.appspot.com',
    ERP_URL: 'https://netresult-platform.appspot.com',
    ADMIN_URL: 'https://admin-dot-netresult-platform.appspot.com'
  },
  BUCKET: {
    INSTRUMENT_NORM_DATASET_BUCKET: 'reportal2_normgroup_data'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
